<template>
  <div>
    <v-card color="transparent" elevation="0" max-width="200px" max-height="200px" class="mx-auto my-auto">
      <v-card-text>
        <center>
          <v-progress-circular indeterminate class="mr-4" color="primary"></v-progress-circular>
          <span>Generating...</span>
        </center>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GenerateSession",
  data: () => {
    return {
      token: "",
      data: "",
    };
  },

  methods: {
    getToken() {
      this.token = this.$route.query.token;
      var base64data = this.$route.query.data;

      if(base64data == '') {
        this.redirect('404');
      }
      
      var locdata = atob(base64data);
      this.data = JSON.parse(locdata);

      this.$store.state.isLoggedIn = true;
      this.storeData(this.data);
      this.redirectToDashboard();
    },
    clear() {
      this.$v.$reset();
      this.username = "";
      this.password = "";
    },
    redirectToDashboard() {
      window.location.assign('/dashboard');
    },
    storeData(data) {
      this.$store.state.apiToken = data.token;
      this.$store.state.loginInfo.username = data.username;
      this.$store.state.loginInfo.firstName = data.first_name;
      this.$store.state.loginInfo.lastName = data.last_name;
      this.$store.state.loginInfo.profileImage = data.user_img;
      this.$store.state.loginInfo.email = data.email;
      this.$store.state.loginInfo.userType = data.user_type;
      this.$store.state.loginInfo.userLevel = data.user_level;
    },
  },

  mounted() {
    this.getToken();
  },
};
</script>
