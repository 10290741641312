<template>
  <div>
    <v-container>
      <page-title>About</page-title>

      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-card class="py-12 px-4 fill-height" style="border-top: 6px solid #ff0000">
            <v-card-text>
              <h1 class="mb-3">PT Beton Perkasa Wijaksana</h1>
              <p>Established since 1983, PT BETON PERKASA WIJAKSANA is the market leader for sales and rental of Formwork & Scaffolding systems in Indonesia, offering cost-effective systems such as the PERI system from Germany.</p>
              <v-img src="https://apibasicweb.betonperkasa.com/photo/about-us/1646800948398-Beton%20Perkasa%20Wijaksana.jpg"></v-img>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-card class="py-12 px-4 fill-height" style="border-top: 6px solid #ff0000">
            <v-card-text>
              <h1 class="mb-3">IT Departement</h1>
              <p>For more information</p>
            </v-card-text>
            <v-list>
              <v-list-item two-line link href="tel:+62215960765">
                <v-list-item-icon>
                  <v-icon large color="primary">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Telp:</v-list-item-title>
                  <v-list-item-subtitle class="text-h4">+62(21) 596 0765</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-icon>
                  <v-icon large color="primary">mdi-deskphone</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                  <v-list-item-title>Ext:</v-list-item-title>
                  <v-list-item-subtitle class="text-h4">110</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line link href="mailto:it.nav@betonperkasa.com">
                <v-list-item-icon>
                  <v-icon large color="primary">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-bold">
                  <v-list-item-title>Email:</v-list-item-title>
                  <v-list-item-subtitle class="text-h4">it.nav@betonperkasa.com</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About",
  mounted() {
    this.requiredLogin()
  }
};
</script>